.Navbarcontents ul li{
    font-size: larger;
    display: inline;
    padding: 20px;
    float: right;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
   height: 100%;
    margin: 0px;
}

#tit{
  font-family:Georgia, 'Times New Roman', Times, serif;
  color: blueviolet;
  font-weight: bolder;
  text-shadow: 2px 2px 2px #000000;
}
#links{
    text-decoration: none;
    display: inline;  
    font-family: cursive;
    font-size: larger;
    font-weight: bold;
    
}
#links:hover{
  color: crimson;
}
.Navbarcontents { 
    width: 100%;
   height: 150px;
}
.maincontent{
  margin-top: 1%;
  height: auto;
 width: 100%;
 
  
}
.content{
   position: relative;
   height: auto;
  width: 600px;
  float: left;
    
}
.loginform{
    margin-left: 30%;
    border: 1px solid black; 
    margin-right: 30%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    background-color: (0,0.3,0.4,0.3);
}
.leftcontent{
  
  margin-left: 50%;
 height: auto;
 float:top;
 width: 500px;
 margin-top: 1%;
 position: relative;

}
.eventsshow{
  
 height: fit-content;
padding: 10px;
 width: 500px;
  display: inline-flex;
}
.datebox{
  display: block;
  padding: 10px;
}
.Month{
  border: 2px solid red; 
  height: 30px;
  width: 70px;
  background-color: red;
  color: white;
  position: relative;
  text-align: center;
}
.date{
  height: 50px;
  width: 70px;
  text-align: center;
  position: relative;
  border: 2px solid red; 
}
.eventtitle{
  font-size: x-large;
  font-weight: bold;
  display: inline;
  width: 800px;
  position:relative;
  margin-right: 30%;
  color: darkblue;
  
}
.eventbody{
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: cadetblue;
  line-height: 18px;
  font-size: medium;
}
.belowcontent{
  width: 100%;
  display:grid;
  grid-template-columns: auto auto auto auto;
  height: fit-content;
  padding: 10px;
  margin: 10px;

}
  
  /****Slider***/
  
  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    height: 400px;
    width: 300px;
    
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  /****Card****/
  .cardcontainer{
    width: 210px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: .5s;
    padding: 13px;
    height: fit-content;
    border-radius: 10px;
    animation: ease-in;
  }
  .cardcontainer:hover{
    transform: scale(1.1);
  }
  .imagecontainer img{
    overflow: hidden;
    height: 200px;
    width: 200px;
  }
  .cardcontent{
    margin-top: .5rem;
text-align: center;
  }
  /*****View Books******/
  .header{
    display: inline-flex;
    
  }
  .opt{
    font-size: larger;
  }
  .cardlayout{
    width: 100%;
    display:grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    height: fit-content;
    border: 1px solid black;
  }
  #butt{
    background-color: blueviolet;
    border-radius: 3px;
    color: white;
    font-size: large;
  }
  /***Advertise****/
  .advert{
    width: 210px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: .5s;
    padding: 13px;
    height: 300px;
    
    animation: ease-in;
    margin: 10px;
    font-size: larger;
    border-radius: 20px;
    font-family:cursive;
  }
  .advert{
    background-color:rgba(0,0.3,0.4,0.3);
  }
  .imgadv image{
    height: 150px;
    width: 150px;
  }
  /********Home advert*****/
  .first{
    background-color: darkcyan;
    margin: 10px;
    width: 210px;
  }
  .second{
    background-color:darkviolet;
    margin: 10px;
    width: 210px;
  }
  .third{
    background-color:dodgerblue;
    margin: 10px;
    width: 210px;
  }
  #tt{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color:darkblue;
  }
  /***Login Form****/
  .loginform{
    border: 1px solid black;
    background-color:azure;
    
  }
  .bg{
    width: 100%;
    
  }
  /*********User*****/
  .profile{
    display: grid;
    grid-template-columns: auto auto;
  }
  .iss{
    border: 1px solid black;
    width: 40%;
  }
  /*********About******/
  .ab{
font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .ab h2{
    color:blueviolet;
    text-decoration: underline;
  }